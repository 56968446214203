<!-- (c) 智汇考题 -->
<template>
  <div class="container">
    <nav-header></nav-header>
    <template>
      <div class="group-form-box">
        <div class="group-title">注册</div>
        <div class="group-item">
         <div class="name">手机号码</div>
          <div class="value">
            <input
              class="input-text"
              type="phone"
              v-model="form.mobile"
              maxlength="11"
              placeholder="请输入手机号码"
            />
            <img
              v-show="form.mobile"
              src="../../assets/img/new/close.png"
              style="width:16px;height:16px;"
              @click="clearMobile()"
            />
          </div>
        </div>
        <div class="group-item">
          <div class="name">密码</div>
          <div class="value">
            <input
              class="input-text"
              type="password"
              v-model="form.password"
              placeholder="请输入新密码"
            />
            <img
              v-show="form.password"
              src="../../assets/img/new/close.png"
              style="width:16px;height:16px;"
              @click="clearPassword()"
            />
          </div>
        </div>
        <div class="group-item">
          <div class="name">验证码</div>
          <div class="value">
            <input
                type="text"
                placeholder="请输入验证码"
                autocomplete="off"
                v-model="form.captcha"
                class="input-text"
                required
            />
          </div>
          <div class="captcha">
            <img
              class="captcha-img"
              :src="captcha.img"
              mode="widthFix"
              @click="getCaptcha"
            />
          </div>
        </div>
      </div>

      <div class="box pl-60 pr-60">
        <div
          class="btn-confirm"
          :class="{ active: form.mobile && form.password&&form.captcha }"
          @click="registerFormValidate()"
        >
          注册
        </div>
        <div class="register" @click="goLogin()">
            <span>已有账号？</span>
            <strong>去登录</strong>
        </div>
      </div>
      <div class="protocol">注册即同意
        <span class="protocol_link" @click="openPage('user_protocol')">《用户服务协议》</span>
        和
        <span class="protocol_link" @click="openPage('user_private_protocol')">《用户隐私协议》</span>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { Toast } from 'vant-green';

Vue.use(Toast);
import NavHeader from "../../components/nav-header.vue";

export default {
  components:{
    NavHeader
  },
  data() {
    return {
      loading: false,
      captcha: {
        img: null,
        key: null,
      },
      form: {
        mobile: "",
        captcha: "",
        password: "",
      }
    };
  },
  mounted() {
    this.getCaptcha();
  },
  methods: {
    getCaptcha() {
      this.captcha.key = Math.random();
      this.$api.Other.Captcha({
        key: this.captcha.key
      }).then((res) => {
        this.captcha.img = res.data.img;
      });
    },
    clearMobile() {
        this.form.mobile = null;
    },
    clearPassword() {
      this.form.password = null;
    },
    openPage(type) {
      this.$router.push({
        name: "Protocol",
        query: {
            type: type,
        },
      });
    },
    registerFormValidate() {
      if (this.loading) {
        return;
      }
      if (!this.form.mobile) {
        return;
      }
      if (!this.form.password) {
        return;
      }
      if (!this.form.captcha) {
        return;
      }
      if (!this.$utils.isChinaMobilePhone(this.form.mobile)) {
        this.$message.error("请输入正确的手机号");
        return;
      }
      if (!this.form.password) {
        this.$message.error("请设置账号密码");
        return;
      }
      if (!this.form.captcha) {
        this.$message.error("请输入图形验证码");
        return;
      }
      this.loading = true;
      Toast.loading({
        type: 'loading',
        forbidClick: true
      });
      this.$api.Auth.PhoneRegister({
        mobile: this.form.mobile,
        key: this.captcha.key,
        captcha: this.form.captcha,
        password: this.form.password,
      })
        .then((resp) => {
          this.loading = false;
          Toast.clear();
          this.$message.success("注册成功");
          this.goLogin();
        })
        .catch((e) => {
          Toast.clear();
          this.loading = false;
          this.form.captcha = "";
          this.$message.error(e.message);
          this.getCaptcha();
        });
    },
    goLogin() {
      if (window.history.length <= 2) {
         this.$router.push({
            name: "Login"
        });
      } else {
        //this.$router.go(-1);
        console.log('back')
        this.$router.back();
      }
    }
  },
};
</script>

<style lang="less" scoped>
.btn-confirm {
  width: 100%;
  height: 48px;
  background-color: rgba(#3ca7fa, 0.6);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
  &.active {
    background: #3ca7fa;
  }
}

.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding-top: 50px;
  float: left;
  background: white;

  .group-form-box {
    width: 100%;
    height: auto;
    float: left;
    margin-top: 0px;
    background-color: white;
    box-sizing: border-box;
    padding: 30px 20px 0 20px;
    .group-title {
      width: 100%;
      height: 24px;
      font-size: 24px;
      font-weight: 500;
      color: #171923;
      line-height: 24px;
      box-sizing: border-box;
      margin-bottom: 30px;
    }
    .group-item {
      width: 100%;
      height: auto;
      float: left;
      box-sizing: border-box;
      padding: 6px 0;
      display: flex;
      border-bottom: 1px solid #f4faff;
      margin-bottom: 50px;
      .mobile {
        width: auto;
        height: auto;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 26px;
        .tit {
          margin-right: 25px;
        }
      }

      .name {
        width: auto;
        height: auto;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        margin-right: 25px;
      }

      .value {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-sizing: border-box;
        align-items: center;
        .input-text {
          max-width: 115px;
          height: 36px;
          float: left;
          box-sizing: border-box;
          outline: none;
          color: #333333;
          font-size: 16px;
          border: none;
        }
      }
    }
  }
}
.captcha-img{
    width: 90px;
    height: 35px;
}
.register {
    width: 100%;
    margin-top: 20px;
    text-align: center;
    color: #666;
    strong {
        color: #3ca7fa;
    }
}
.protocol {
  position: fixed;
  bottom: 20px;
  width: 100%;
  text-align: center;
  color: #999;
  .protocol_link {
    color: #3ca7fa;
  }
}
</style>
